exports.components = {
  "component---src-pages-2017-index-js": () => import("./../../../src/pages/2017/index.js" /* webpackChunkName: "component---src-pages-2017-index-js" */),
  "component---src-pages-2022-2022-js": () => import("./../../../src/pages/2022/2022.js" /* webpackChunkName: "component---src-pages-2022-2022-js" */),
  "component---src-pages-2022-coros-js": () => import("./../../../src/pages/2022/coros.js" /* webpackChunkName: "component---src-pages-2022-coros-js" */),
  "component---src-pages-2022-index-js": () => import("./../../../src/pages/2022/index.js" /* webpackChunkName: "component---src-pages-2022-index-js" */),
  "component---src-pages-2022-juri-js": () => import("./../../../src/pages/2022/juri.js" /* webpackChunkName: "component---src-pages-2022-juri-js" */),
  "component---src-pages-2022-localizacao-js": () => import("./../../../src/pages/2022/localizacao.js" /* webpackChunkName: "component---src-pages-2022-localizacao-js" */),
  "component---src-pages-2022-programa-js": () => import("./../../../src/pages/2022/programa.js" /* webpackChunkName: "component---src-pages-2022-programa-js" */),
  "component---src-pages-2022-regulamento-js": () => import("./../../../src/pages/2022/regulamento.js" /* webpackChunkName: "component---src-pages-2022-regulamento-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-atelier-js": () => import("./../../../src/pages/atelier.js" /* webpackChunkName: "component---src-pages-atelier-js" */),
  "component---src-pages-concurso-js": () => import("./../../../src/pages/concurso.js" /* webpackChunkName: "component---src-pages-concurso-js" */),
  "component---src-pages-coros-js": () => import("./../../../src/pages/coros.js" /* webpackChunkName: "component---src-pages-coros-js" */),
  "component---src-pages-en-js": () => import("./../../../src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-juri-js": () => import("./../../../src/pages/juri.js" /* webpackChunkName: "component---src-pages-juri-js" */),
  "component---src-pages-localizacao-js": () => import("./../../../src/pages/localizacao.js" /* webpackChunkName: "component---src-pages-localizacao-js" */),
  "component---src-pages-regulamento-js": () => import("./../../../src/pages/regulamento.js" /* webpackChunkName: "component---src-pages-regulamento-js" */)
}

